import { ReactNode } from "react";
import { AIWritingTool, AIWritingToolStyle } from "../../Hooks/useProjectNotes";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { IoBook } from "react-icons/io5";
import { IoBriefcase } from "react-icons/io5";
import { SiTarget } from "react-icons/si";
import { FaListUl, FaSun } from "react-icons/fa6";
import { FaSpellCheck } from "react-icons/fa";
import { MdAutoFixHigh } from "react-icons/md";
import { FaWineGlassAlt } from "react-icons/fa";
import { FaArrowDownWideShort } from "react-icons/fa6";
import { FaArrowUpWideShort } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { TbWritingSign } from "react-icons/tb";
import { MdHdrStrong } from "react-icons/md";
import { TbBlockquote } from "react-icons/tb";
import { FaThumbsUp } from "react-icons/fa";
import { BsFilePostFill } from "react-icons/bs";
import { FaLightbulb } from "react-icons/fa6";
import { LuListTodo } from "react-icons/lu";
import { VscDebugContinue } from "react-icons/vsc";
import { FaQuestion } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";

export interface AIToolGroup {
  label: string;
  icon: ReactNode;
  tools: (AIWritingTool | AIToolGroup)[];
  requiresSelection?: boolean;
}

export const writingToolGroups: AIToolGroup[] = [
  {
    label: "General writing",
    icon: CoursableIcons.Sparkles(),
    tools: [
      { label: "Improve writing", icon: <MdAutoFixHigh />, prompt: "Improve this text's writing." },
      { label: "Fix spelling & grammar", icon: <FaSpellCheck />, prompt: "Fix any spelling and grammar mistakes in the provided text. If everything is correct, leave it as is, even if its just one word." },
      { label: "Make shorter", icon: <FaArrowDownWideShort />, prompt: "Make this text shorter." },
      { label: "Make longer", icon: <FaArrowUpWideShort />, prompt: "Make this text longer." },
      {
        label: "Continue writing",
        icon: <VscDebugContinue />,
        prompt: "Continue writing from where the user stopped. Don't write a lot (up to one paragraph), just finish the user's initial thought. Don't include what the user has already written, start from the next sentence.",
        useMaterials: true,
        replaceSelection: false,
      },
      {
        label: "Change tone",
        icon: <TbWritingSign />,
        tools: [
          {
            label: "Academic",
            icon: CoursableIcons.GraduationCapFill(),
            prompt:
              "Rewrite this text to sound more academic. Compose your text with a formal tone, using precise language and supporting evidence where applicable. Ensure your arguments are well-structured and cite credible sources to back your claims. Only rewrite the provided text, do no make it longer or shorter.",
          },
          {
            label: "Professional",
            icon: <IoBriefcase />,
            prompt:
              "Rewrite this text to sound more professional. Write your text in a clear and concise manner, maintaining a professional tone. Focus on delivering your message effectively with appropriate terminology and a structured format. Only rewrite the provided text, do no make it longer or shorter.",
          },
          {
            label: "Casual",
            icon: <IoBook />,
            prompt:
              "Rewrite this text to sound more casual. Draft your text in a relaxed and informal style, as if you were having a conversation with a friend. Feel free to use contractions, colloquial expressions, and a friendly tone. Only rewrite the provided text, do no make it longer or shorter.",
          },
          {
            label: "Straightforward",
            icon: <SiTarget />,
            prompt:
              "Rewrite this text to sound more straightforward. Write your text in a clear and simple manner, avoiding unnecessary jargon or complex language. Focus on delivering your message directly and succinctly, ensuring it is easy to understand. Only rewrite the provided text, do no make it longer or shorter.",
          },
          {
            label: "Confident",
            icon: <MdHdrStrong />,
            prompt:
              "Rewrite this text to sound more confident. Craft your text with a self-assured tone, using assertive language and decisive statements. Make clear and definitive points, backing them up with strong evidence and logical reasoning. Only rewrite the provided text, do no make it longer or shorter.",
          },
          {
            label: "Friendly",
            icon: <FaSun />,
            prompt:
              "Rewrite this text to sound more friendly. Write your text in a warm and approachable manner, as though you are speaking to a close friend. Use a positive tone, personal anecdotes, and friendly language to connect with your reader. Only rewrite the provided text, do no make it longer or shorter.",
          },
        ],
      },
      {
        label: "Simplify language",
        icon: <FaCheck />,
        prompt: "Simplify language in this text. Make it more understandable, avoid complex sentences and jargon, focusing instead on clarity and simplicity to ensure your message is accessible to everyone.",
      },
      {
        label: "Advance language",
        icon: <FaWineGlassAlt />,
        prompt: "Make the languge more advances. Incorporating advanced vocabulary, complex sentence structures, and specialized terminology. Catering to an audience with a higher level of comprehension and expertise.",
      },
      { label: "Explain", icon: <FaRegCircleQuestion />, prompt: "Explain the meaning of the provided text." },
      { label: "Ask anything...", icon: CoursableIcons.Edit(), requireUserPrompt: true, userPromptPlaceholder: "Ask AI to edit your text in any way." },
    ],
    requiresSelection: true,
  },
  {
    label: "Your materials",
    icon: CoursableIcons.Sparkles(),
    tools: [
      { label: "Summarize", icon: <TbBlockquote />, prompt: "Create a summary of the uploaded documents.", useMaterials: true },
      {
        label: "Essay outline",
        icon: <FaListUl />,
        prompt: "Create an outline for the essay, using instructions from the user's prompt.",
        useMaterials: true,
        requireUserPrompt: true,
        userPromptPlaceholder: "What should the essay be about? Topics it needs to cover, goals, thesis statement, assignment, or anything else.",
      },
      {
        label: "Brainstorm ideas",
        icon: <FaLightbulb />,
        prompt: "Brainstorm ideas about the topic asked by the user.",
        useMaterials: true,
        requireUserPrompt: true,
        userPromptPlaceholder: "What do you want to brain storm ideas about?",
      },
      {
        label: "Pros and cons",
        icon: <FaThumbsUp />,
        prompt: "Create a pros and cons list about the topic asked by the user.",
        useMaterials: true,
        requireUserPrompt: true,
        userPromptPlaceholder: "What should the pros and cons list be about?",
      },
      {
        label: "Social media post",
        icon: <BsFilePostFill />,
        prompt: "Create a social media post about the topic asked by the user.",
        useMaterials: true,
        requireUserPrompt: true,
        userPromptPlaceholder: "What should the post be about?",
      },
      {
        label: "Find action items",
        icon: <LuListTodo />,
        prompt: "Find action items in the uploaded documents.",
        useMaterials: true,
      },
      { label: "Ask anything...", icon: CoursableIcons.Edit(), useMaterials: true, requireUserPrompt: true, userPromptPlaceholder: "Ask AI to generate anything in regards to your materials and current selection." },
    ],
  },
];

export const writingToolStyles: AIWritingToolStyle[] = [
  { name: "Academic", icon: CoursableIcons.GraduationCapFill(), color: "#b436eb" },
  { name: "Professional", icon: <IoBriefcase />, color: "#eb8a36" },
  { name: "Casual", icon: <IoBook />, color: "#2489d1" },
  { name: "Friendly", icon: <FaSun />, color: "#e8bd2e" },
  { name: "Simple", icon: <SiTarget />, color: "#3ccc23" },
];
