import React from "react";
import LoadingIndicator from "../LoadingIndicator";
import { cn } from "../../../utils/UtilityMethods";
import CoursableIcons from "../../../utils/CoursableIcons";

interface ContextMenuProps {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  direction?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
  closeOnClick?: boolean;
  autoClose?: boolean;
  children?: React.ReactNode;
  className?: string;
  menuClassName?: string;
  menuStyle?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ContextMenu = ({ isOpen, setIsOpen, menuStyle, direction = "bottom", align = "end", autoClose, closeOnClick, children, className, menuClassName, onMouseEnter, onMouseLeave }: ContextMenuProps) => {
  return (
    <div
      onMouseLeave={() => {
        if (autoClose === undefined || autoClose) setIsOpen(false);
        onMouseLeave?.();
      }}
      onMouseEnter={onMouseEnter}
      className={cn(
        "absolute duration-100 z-50 p-4",
        isOpen ? "opacity-100" : "opacity-0 scale-90 pointer-events-none",

        direction === "bottom" && "top-full pt-1",
        direction === "top" && "bottom-full pb-1",
        direction === "right" && "left-full pl-1",
        direction === "left" && "right-full pr-1",

        direction === "bottom" && align === "start" && "-left-4 origin-top-left",
        direction === "bottom" && align === "center" && "left-1/2 -translate-x-1/2 origin-top",
        direction === "bottom" && align === "end" && "-right-4 origin-top-right",

        direction === "top" && align === "start" && "-left-4 origin-bottom-left",
        direction === "top" && align === "center" && "left-1/2 -translate-x-1/2 origin-bottom",
        direction === "top" && align === "end" && "-right-4 origin-bottom-right",

        direction === "right" && align === "start" && "-top-4 origin-top-left",
        direction === "right" && align === "center" && "top-1/2 -translate-y-1/2 origin-left",
        direction === "right" && align === "end" && "-bottom-4 origin-bottom-left",

        direction === "left" && align === "start" && "-top-4 origin-top-right",
        direction === "left" && align === "center" && "top-1/2 -translate-y-1/2 origin-right",
        direction === "left" && align === "end" && "-bottom-4 origin-bottom-right",

        className
      )}
    >
      <div
        style={menuStyle}
        onClick={(e) => {
          e.stopPropagation();
          if (closeOnClick === undefined || closeOnClick) setIsOpen(false);
        }}
        className={cn("bg-background dark:bg-systemGray-100 rounded-md shadow-lg dark:shadow-systemGray-200 border border-systemGray-200 flex flex-col z-50", menuClassName)}
      >
        {children}
      </div>
    </div>
  );
};

interface ContextMenuButtonProps {
  onClick?: () => void;
  label?: string;
  icon?: React.ReactNode;
  destructive?: boolean;
  disabled?: boolean;
  className?: string;
}

export const ContextMenuButton = ({ label, icon, destructive = false, disabled, onClick, className }: ContextMenuButtonProps) => {
  const destructiveClasses = "text-red-500 hover:bg-red-50 dark:hover:bg-red-950/70 active:bg-red-100 dark:active:bg-red-950 disabled:pointer-events-none";
  const regularClasses = "text-foreground hover:bg-systemGray-100 dark:hover:bg-systemGray-200 active:bg-systemGray-200 dark:active:bg-systemGray-300 disabled:pointer-events-none";

  return (
    <button
      disabled={disabled}
      onClick={(e) => {
        if (disabled) {
          e.stopPropagation();
          return;
        }
        onClick?.();
      }}
      className={cn(!!destructive ? destructiveClasses : regularClasses, "w-full min-w-max flex items-center justify-between p-2 px-3 text-sm text-left disabled:opacity-50 first:rounded-t-md last:rounded-b-md", className)}
    >
      {label ? <div className="mr-8">{label}</div> : <div />}
      {icon && icon}
    </button>
  );
};

interface ContextMenuSubmenuProps {
  label: string;
  icon?: React.ReactNode;
  align?: "start" | "center" | "end";
  direction?: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode;
}

export const ContextMenuSubmenu = ({ label, icon, children, align = "start", direction = "right" }: ContextMenuSubmenuProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} className="relative group">
      <ContextMenuButton label={label} icon={icon ?? CoursableIcons.Chevron("right", "scale-90 text-systemGray-500")} className="rounded-none first:rounded-none last:rounded-none group-first:rounded-t-md group-last:rounded-b-md" />
      <ContextMenu isOpen={open} setIsOpen={setOpen} direction={direction} align={align}>
        {children}
      </ContextMenu>
    </div>
  );
};

export const ContextMenuDivider = ({ className }: { className?: string }) => {
  return <div className={cn("w-full bg-systemGray-200 h-[2px]", className)} />;
};

export const ContextMenuLabel = ({ label, icon, width, className }: { label: string; icon?: React.ReactNode; width?: number; className?: string }) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        width: width !== undefined ? `${width}px` : "auto",
      }}
      className={cn(`text-foreground p-2 px-3 flex items-center justify-between text-sm text-left font-semibold`, width === undefined && "min-w-max", className)}
    >
      {label ? <div className={`${icon !== undefined && "mr-8"}`}>{label}</div> : <div />}
      {icon && icon}
    </div>
  );
};

export const ContextMenuLoadingLabel = ({ label }: { label: string }) => {
  return <ContextMenuLabel label={label} icon={<LoadingIndicator className="stroke-systemGray-500 h-[14px] w-[14px]" />} className="text-systemGray-500" />;
};
