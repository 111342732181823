import { useEffect, useRef, useState } from "react";
import { MultipleChoiceQuestion, ProjectQuiz } from "../../../../../backend/Projects/types";
import { PopupModal, PopupModalContent, PopupModalFooter, PopupModalHeader } from "../../../../elements/PopupModal";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import Button from "../../../../elements/Button";
import ConfettiExplosion from "react-confetti-explosion";
import useRollingAppear from "../../../../../utils/Hooks/useRollingAppear";
import { cn } from "../../../../../utils/UtilityMethods";

const ProjectQuizzesPractice = ({ quiz, isOpen, setIsOpen }: { quiz: ProjectQuiz; isOpen: boolean; setIsOpen: (b: boolean) => void }) => {
  const shuffleQuiz = (quiz: ProjectQuiz) => {
    const newQuiz: ProjectQuiz = JSON.parse(JSON.stringify(quiz)); // Deep copy
    const shuffledQuiz = { ...newQuiz, multipleChoice: newQuiz.multipleChoice.sort(() => Math.random() - 0.5) };
    return shuffledQuiz;
  };

  const [shuffledQuiz, setShuffledQuiz] = useState<ProjectQuiz>(shuffleQuiz(quiz));
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const [duration, setDuration] = useState<number>(0);

  const [selectedAnswers, setSelectedAnswers] = useState<(number | null)[]>(Array(shuffledQuiz.multipleChoice.length).fill(null));
  const correctAnswers = selectedAnswers.filter((answer, index) => answer === shuffledQuiz.multipleChoice[index].correctChoice - 1).length;
  const precentCorrect = correctAnswers / shuffledQuiz.multipleChoice.length;

  const questionsRef = useRef<HTMLDivElement | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [viewAnswers, setViewAnswers] = useState<boolean>(false);

  const { indexShown, StartRollingAppear, Reset } = useRollingAppear(8, 500, 0);

  function SubmitQuiz() {
    setSubmitted(true);
    StopTimer();
    StartRollingAppear();
  }

  function SelectAnswer(index: number, answer: number) {
    setSelectedAnswers((prev) => {
      const newArr = [...prev];
      newArr[index] = answer;
      return newArr;
    });
  }

  function StartTimer() {
    setDuration(0);
    const start = Date.now();
    timerRef.current = setInterval(() => {
      setDuration(Math.floor((Date.now() - start) / 1000));
    }, 1000);
  }

  function StopTimer() {
    if (timerRef.current) clearInterval(timerRef.current);
  }

  function StartQuiz(wrongOnly: boolean = false) {
    Reset();
    setSubmitted(false);
    setViewAnswers(false);

    if (wrongOnly) {
      const copyQuiz: ProjectQuiz = JSON.parse(JSON.stringify(shuffledQuiz));
      const wrongQuestions = copyQuiz.multipleChoice.filter((question, index) => selectedAnswers[index] !== question.correctChoice - 1);
      copyQuiz.multipleChoice = wrongQuestions;

      setShuffledQuiz(shuffleQuiz(copyQuiz));
      setSelectedAnswers(Array(copyQuiz.multipleChoice.length).fill(null));
    } else {
      const newQuiz = shuffleQuiz(quiz);
      setShuffledQuiz(newQuiz);
      setSelectedAnswers(Array(newQuiz.multipleChoice.length).fill(null));
    }
    StartTimer();
  }

  useEffect(() => {
    questionsRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [questionsRef.current]);

  useEffect(() => {
    if (isOpen) {
      StartQuiz();
    } else {
      StopTimer();
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      StopTimer();
    };
  }, []);

  return (
    <PopupModal className="w-full max-w-3xl" open={isOpen} Close={() => setIsOpen(false)}>
      <PopupModalHeader className="w-full flex-centered flex-col gap-2">
        <div className="text-center text-xs text-brand-500 font-normal">PRACTICE</div>
        <div className="text-center font-semibold text-lg">{shuffledQuiz.name}</div>
      </PopupModalHeader>
      <PopupModalContent className="w-full flex-started flex-col overflow-auto">
        {!submitted || viewAnswers ? (
          <div className="w-full flex-started flex-col">
            <div className="w-full flex items-center justify-between text-sm text-systemGray-400 font-light pb-1">
              <div>
                {submitted || viewAnswers ? "Completed in " : ""}
                {timeString(duration)}
              </div>
              {!viewAnswers && (
                <div>
                  {selectedAnswers.filter((answer) => answer != null).length}/{shuffledQuiz.multipleChoice.length}
                </div>
              )}
            </div>
            <div ref={questionsRef} className="w-full flex items-start justify-start flex-col gap-8 px-2 md:px-4 py-8">
              {shuffledQuiz.multipleChoice.map((question, index) => (
                <MultipleChoiceQuestionView key={index} question={question} index={index} selectedAnswer={selectedAnswers[index]} submitted={submitted} SelectAnswer={(answer) => SelectAnswer(index, answer)} />
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full flex-centered flex-col overflow-auto gap-8 pt-16 relative">
            <div className={cn("text-3xl opacity-0", indexShown >= 2 && "opacity-100 duration-300")}>
              {precentCorrect >= 0.9 ? <>Awesome 🔥</> : precentCorrect >= 0.75 ? <>Well done 😀</> : precentCorrect >= 0.5 ? <>Good job 🙂</> : <>😔</>}
            </div>
            <div className={cn("text-5xl font-bold ease-out -translate-y-8", indexShown >= 0 ? "opacity-100 -translate-y-0 duration-300 " : "opacity-0")}>{(precentCorrect * 100).toFixed(0)}% correct</div>
            <div className={cn("text-3xl ease-out -translate-y-8 text-systemGray-500", indexShown >= 1 ? "opacity-100 -translate-y-0 duration-300 " : "opacity-0")}>
              You got {correctAnswers} questions out of {shuffledQuiz.multipleChoice.length}
            </div>
            {precentCorrect >= 0.7 && indexShown >= 3 && <ConfettiExplosion zIndex={50} duration={3000} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />}
            <div className={cn("w-full flex-centered pt-16 pb-8 gap-4", indexShown >= 7 || (precentCorrect < 0.7 && indexShown >= 3) ? "opacity-100 duration-300" : "pointer-events-none opacity-0")}>
              <Button variant="outline" onClick={() => setViewAnswers(true)}>
                View answers
              </Button>
              <Button variant="outline" onClick={() => StartQuiz(false)}>
                Retry all
              </Button>
              {correctAnswers !== shuffledQuiz.multipleChoice.length && <Button onClick={() => StartQuiz(true)}>Retry wrong only</Button>}
            </div>
          </div>
        )}
      </PopupModalContent>
      {(!submitted || viewAnswers) && (
        <PopupModalFooter className="w-full flex-centered gap-4">
          {viewAnswers ? (
            <>
              <Button variant="outline" onClick={() => StartQuiz(false)}>
                Retry all
              </Button>
              {correctAnswers !== shuffledQuiz.multipleChoice.length && <Button onClick={() => StartQuiz(true)}>Retry wrong only</Button>}
            </>
          ) : (
            <Button disabled={selectedAnswers.filter((answer) => answer != null).length < shuffledQuiz.multipleChoice.length} onClick={SubmitQuiz}>
              Submit
            </Button>
          )}
        </PopupModalFooter>
      )}
    </PopupModal>
  );
};

export default ProjectQuizzesPractice;

interface MultipleChoiceQuestionViewProps {
  question: MultipleChoiceQuestion;
  index: number;
  selectedAnswer: number | null;
  submitted: boolean;
  SelectAnswer: (answer: number) => void;
}

const MultipleChoiceQuestionView = ({ question, index, selectedAnswer, submitted, SelectAnswer }: MultipleChoiceQuestionViewProps) => {
  const optionLetter = (index: number): string => {
    return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"][index].toUpperCase();
  };

  return (
    <div className="w-full flex flex-col items-start justify-start gap-4">
      <div className="font-semibold flex items-start justify-center gap-2 text-sm md:text-base">
        <span className="text-systemGray-400 font-normal">{index + 1}.</span>
        {question.question}
      </div>
      <div className="w-full flex flex-col items-start justify-start gap-2 pl-2 md:pl-4">
        {question.choices.map((choice, index) => (
          <OptionView key={index} option={choice} optionLetter={optionLetter(index)} submitted={submitted} isSelected={selectedAnswer === index} isCorrect={question.correctChoice - 1 === index} Select={() => SelectAnswer(index)} />
        ))}
      </div>
    </div>
  );
};

const OptionView = ({ option, optionLetter, submitted, isSelected, isCorrect, Select }: { option: string; optionLetter: string; submitted: boolean; isSelected: boolean; isCorrect: boolean; Select: () => void }) => {
  const [reveal, setReveal] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReveal(submitted);
    }, 150);
  }, [submitted]);

  return (
    <button
      disabled={submitted}
      onClick={Select}
      className={`flex items-start justify-start gap-2 md:gap-4 border border-transparent hover:bg-systemGray-100 hover:border-systemGray-300 active:bg-systemGray-200 hover:shadow duration-100 rounded-2xl p-1 disabled:pointer-events-none`}
    >
      <div
        style={{
          transform: submitted && isSelected ? "rotateY(180deg)" : "rotateY(0deg)",
          transition: "transform 0.3s linear, background-color 0.1s linear, border-color 0.1s linear, color 0.1s linear",
        }}
        className={`h-6 md:h-7 w-6 md:w-7 flex-centered aspect-square ${
          isSelected
            ? reveal
              ? isCorrect
                ? "text-green-500 border-green-500 bg-green-200 dark:bg-green-950"
                : "text-red-500 border-red-500 bg-red-200 dark:bg-red-950"
              : "text-brandBlue-500 bg-brandBlue-50 border-brandBlue-500"
            : "text-systemGray-500 bg-systemGray-200"
        } border text-xs rounded-full`}
      >
        {isSelected && reveal ? (isCorrect ? CoursableIcons.Check("-scale-x-100") : CoursableIcons.Xmark()) : optionLetter}
      </div>

      <div className={`text-sm md:text-base text-left mr-2 translate-y-0.5 ${isSelected ? `${reveal ? (isCorrect ? "text-green-500" : "text-red-500") : "text-brandBlue-500"}` : "text-foreground"}`}>
        {option || <span className="text-systemGray-500">Empty option</span>}
      </div>
    </button>
  );
};

const timeString = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  if (hours > 0) return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
