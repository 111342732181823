import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ContextMenu, ContextMenuButton, ContextMenuDivider, ContextMenuLoadingLabel } from "../../../../elements/DropdownMenu/ContextMenu";
import Dropdown from "../../../../elements/DropdownMenu/Dropdown";
import { useProjectManager } from "../../Hooks/useProjectManager";
import NoteEditor from "./NoteEditor";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { WaitFor } from "../../../../../utils/UtilityMethods";
import { DoneButton } from "../Shared";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import InputField from "../../../../elements/InputField";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import React from "react";
import { ContextMenuDeleteConfirmation } from "../../../../elements/DropdownMenu/ContextMenuConfirmation";

const ProjectNotes = () => {
  const { project, Notes } = useProjectManager();

  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number | null>(Notes.notes.length > 0 ? 0 : null);
  const selectedNote = selectedNoteIndex !== null ? Notes.notes[selectedNoteIndex] : null;

  const [noteName, setNoteName] = useState(selectedNote?.name ?? null);
  const [savingName, setSavingName] = useState(false);
  const [stats, setStats] = useState<{ words: number; characters: number }>({ words: 0, characters: 0 });

  const saveTimer = useRef<NodeJS.Timeout | null>(null);
  const [saving, setSaving] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [exportDocx, setExportDocx] = useState<undefined | boolean>(undefined);

  const { sendError } = useNotifications();

  async function SaveContentWithDelay(content: string) {
    if (!selectedNote || deleting) return; // Skip saving if the file is being deleted.

    saveTimer.current && clearTimeout(saveTimer.current);
    saveTimer.current = setTimeout(async () => {
      setSaving(true);
      await Notes.SaveContent(selectedNote.id, content);
      await WaitFor(0.2);
      setSaving(false);
    }, 1000);
  }

  async function ClickSaveNoteName() {
    if (!selectedNote) return;

    setSavingName(true);
    try {
      await Notes.SaveName(selectedNote.id, noteName ?? "");
      setRenaming(false);
    } catch (error) {
      sendError();
    }
    setSavingName(false);
  }

  async function ClickDeleteNote() {
    if (!selectedNote) return;

    setDeleting(true);
    try {
      await Notes.Delete(selectedNote.id);
    } catch (error) {
      sendError();
    }
    setDeleting(false);
  }

  const onCreate = useCallback(
    (html: string, text: string) => {
      setStats({ words: nWords(text), characters: text.length });
    },
    [selectedNote, selectedNoteIndex, setStats]
  );
  const onContentChange = useCallback(
    (html: string, text: string) => {
      SaveContentWithDelay(html);
      setStats({ words: nWords(text), characters: text.length });
    },
    [selectedNote, selectedNoteIndex, deleting, setStats]
  );

  useEffect(() => {
    setSelectedNoteIndex(Notes.notes.length > 0 ? 0 : null);
  }, [Notes.notes]);

  useEffect(() => {
    setNoteName(selectedNote?.name ?? null);
  }, [selectedNote]);

  return (
    <div className="w-full h-full flex-started flex-col py-4 gap-4 overflow-auto">
      <div className="w-full flex items-center justify-between border-b pb-4 px-4 overflow-x-clip gap-4 @container z-1">
        {renaming ? (
          <>
            <InputField className="w-full" value={noteName ?? ""} onValueChange={setNoteName} />
            <DoneButton onClick={ClickSaveNoteName} saving={savingName} />
          </>
        ) : (
          selectedNote && (
            <>
              <div className="w-full flex flex-col items-start justify-start">
                <div className="text-brand-500 text-sm md:text-base w-full">{selectedNote?.name}</div>
                <div className="text-systemGray-400 text-xs font-light">
                  Words {stats.words} | Characters {stats.characters}
                </div>
              </div>
              <Dropdown
                label={
                  <>
                    <span className="hidden @md:inline">More</span>
                    {CoursableIcons.Ellipsis()}
                  </>
                }
                chevron={false}
                className="@md:w-auto h-9 w-9"
              >
                <ContextMenuButton label="Rename" icon={CoursableIcons.Edit()} onClick={() => setRenaming(true)} />
                <ContextMenuButton
                  label="Save as PDF"
                  icon={CoursableIcons.Share()}
                  onClick={() => {
                    window.open(`/app/projects/${project?.id}/notes/${selectedNote.id}/pdf`, "_blank");
                  }}
                />
                <ContextMenuButton
                  label="Save as Word"
                  icon={CoursableIcons.Share()}
                  onClick={() => {
                    setExportDocx(!exportDocx);
                    setTimeout(() => setExportDocx(undefined), 100);
                  }}
                />
                <ContextMenuDivider />
                <ContextMenuButton destructive label="Delete" icon={CoursableIcons.Delete()} onClick={() => setShowDeleteConfirmation(true)} />
              </Dropdown>
              <ContextMenuDeleteConfirmation label="Are you sure you want to delete this note?" width={200} isOpen={showDeleteConfirmation} setIsOpen={setShowDeleteConfirmation} onDelete={ClickDeleteNote} />
              <ContextMenu autoClose={false} isOpen={deleting} setIsOpen={setDeleting}>
                <ContextMenuLoadingLabel label="Deleting" />
              </ContextMenu>
            </>
          )
        )}
      </div>
      {selectedNote && (
        <div className="flex flex-col w-full h-full pt-1 px-4 gap-1 overflow-auto z-0">
          <div className="text-mini text-systemGray-400 flex items-center justify-start gap-1 px-2">
            {saving ? (
              <>
                Saving <LoadingIndicator className="w-3 h-3 stroke-systemGray-400" />
              </>
            ) : (
              "Saved"
            )}
          </div>
          <MemoNoteEditor noteName={selectedNote.name} content={selectedNote.content} onCreate={onCreate} onContentChange={onContentChange} exportDocx={exportDocx} />
        </div>
      )}
    </div>
  );
};

export default ProjectNotes;

const nWords = (str: string) => str.split(" ").filter((s) => s.length > 0).length;
const MemoNoteEditor = React.memo(NoteEditor);
