import { ReactNode } from "react";
import { HashLink } from "react-router-hash-link";
import CoursableIcons from "../../../utils/CoursableIcons";
import Button from "../../elements/Button";
import { LargeWaves, SmallWaves } from "../../elements/VectorWaves/Waves";
import { cn } from "../../../utils/UtilityMethods";

export function HomePageScrollCTA({ children, href }: { children: ReactNode; href: string }) {
  return (
    <div className="w-full flex-centered px-4">
      <HashLink to={href} smooth className="flex-centered gap-1 text-systemGray-600 hover:text-foreground active:text-systemGray-800 duration-300 group">
        {CoursableIcons.Chevron("down", "text-brand-200 mx-1 animate-bounce mt-[5px]")}
        <p className="text-mini md:text-base text-center">{children}</p>
        {CoursableIcons.Chevron("down", "text-brand-200 mx-1 animate-bounce mt-[5px]")}
      </HashLink>
    </div>
  );
}

export const HomePanelBase = ({ title, description, icon, children }: { title: string; description: string; icon: React.ReactNode; children: React.ReactNode }) => {
  return (
    <div className="w-full flex flex-col items-center justify-start gap-4 rounded-2xl bg-systemGray-200/75 hover:bg-background hover:shadow-xl dark:hover:shadow-brand-200 hover:border-brand-300 border-transparent border-2 duration-300 p-4 md:p-8 h-full">
      <div className="text-brand-500 text-3xl md:text-4xl">{icon}</div>
      <h3 className="font-semibold text-2xl md:text-3xl text-foreground">{title}</h3>
      <h5 className="text-systemGray-700 w-full my-2 text-center max-w-md text-sm md:text-base">{description}</h5>
      {children}
      <div className="grow" />
      <Button size="lg" className="text-base shrink-0" to={title.toLowerCase()}>
        Explore {title}
      </Button>
    </div>
  );
};

interface WaveWrapperProps {
  children?: ReactNode;
  id?: string;
  className?: string;
  topWavesContainerClassName?: string;
  bottomWavesContainerClassName?: string;
}

export function WaveWrapper({ children, id, className, topWavesContainerClassName, bottomWavesContainerClassName }: WaveWrapperProps) {
  return (
    <div id={id} className={cn("w-full flex-centered flex-col", className)}>
      <LargeWaves className={topWavesContainerClassName} />
      <div className="w-full flex-centered bg-brand-700 dark:bg-brand-300">{children}</div>
      <SmallWaves className={bottomWavesContainerClassName} />
    </div>
  );
}
