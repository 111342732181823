"use client";

import React, { useEffect } from "react";
import { ReactNode } from "react";
import { cn } from "../../../utils/UtilityMethods";

function TextSlideAnimation({ children, className }: { children: ReactNode; className?: string }) {
  const array = React.Children.toArray(children) as React.ReactElement[];

  const [currentIndex, setCurrentIndex] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % array.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={cn("h-11 md:h-12 overflow-clip pt-1", className)}>
      <div
        style={{
          transform: `translateY(-${(currentIndex * 100) / array.length}%)`,
        }}
        className="flex-started flex-col duration-500 ease-out-back"
      >
        {array.map((child, index) => {
          return (
            <div key={index} className={cn("pb-4 duration-500", currentIndex === index ? "opacity-100" : "opacity-0")}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TextSlideAnimation;
