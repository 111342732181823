import { TypeAnimation } from "react-type-animation";
import CoursableIcons from "../../../utils/CoursableIcons";
import useRollingAppear from "../../../utils/Hooks/useRollingAppear";
import { useMemo, useState } from "react";
import { HomePanelBase } from "./Shared";

const HomeCoursesPanel = () => {
  const [shownCourse, setShownCourse] = useState(0);
  const { indexShown, StartRollingAppear, StartRollingDisappear } = useRollingAppear(3);

  const pm = [
    {
      title: "1. Introduction to Product Management",
      description: "In this topic, you will learn the basics of product management and understand the role and responsibilities of a product manager.",
    },
    {
      title: "2. Defining Product Strategy",
      description: "This a crucial step in successful product management. It sets the direction and priorities for your product and ensures that all decisions align with your overall vision.",
    },
    {
      title: "3. Product Roadmapping and Prioritization",
      description: "Product roadmapping and prioritization are essential skills for effective product management. A product roadmap is a visual representation of the future direction of a product over a certain time period.",
    },
  ];

  const biology = [
    {
      title: "1. DNA Structure and Function",
      description:
        "DNA (deoxyribonucleic acid) is the hereditary material in humans and almost all other organisms. It is a long molecule made up of units called nucleotides, which are composed of a sugar, a phosphate group, and a nitrogenous base.",
    },
    {
      title: "2. DNA Replication Process",
      description: "DNA replication is the fundamental process by which cells make exact copies of their genetic information. This process is crucial for cell division, growth, and development.",
    },
    {
      title: "3. Gene Expression and Protein Synthesis",
      description:
        "Gene expression and protein synthesis are fundamental processes in molecular biology. Gene expression involves the transcription of DNA into mRNA, followed by the translation of mRNA into a specific sequence of amino acids to form a protein.",
    },
  ];

  const negotiations = [
    {
      title: "1. Understanding Negotiation",
      description: "Understanding negotiation is crucial for mastering negotiation skills. Negotiation is a strategic communication process wherein two or more parties seek a mutually beneficial outcome.",
    },
    {
      title: "2. Preparation and Planning",
      description: "Preparation and planning are essential in negotiation to achieve optimal results. This phase involves gathering information about the other party's interests, needs, and potential objections.",
    },
    {
      title: "3. Communication and Active Listening",
      description: "Communication and active listening play a vital role in successful negotiation. Communication involves the exchange of information, ideas, and feelings between two or more people.",
    },
  ];

  const courses = useMemo(() => [pm, biology, negotiations], []);

  function ShowCourse(index: number) {
    setShownCourse(index);
    StartRollingAppear();
  }

  return (
    <HomePanelBase title="Courses" description="Generate personalized courses with approved study materials from the web." icon={CoursableIcons.GraduationCap()}>
      <div className="w-full flex flex-col justify-center items-center h-full gap-4 border border-systemGray-300 bg-systemGray-100 rounded-lg p-4 select-none pointer-events-none">
        <div className="w-full text-mini md:text-sm bg-background py-2 px-3 rounded-lg flex gap-1 items-center shadow">
          <div className="font-semibold text-brand-500 shrink-0">Teach me:</div>
          <TypeAnimation
            className="line-clamp-1"
            speed={60}
            deletionSpeed={80}
            repeat={Infinity}
            sequence={[
              "project management for beginners",
              () => ShowCourse(0),
              5000,
              () => StartRollingDisappear(),
              "molecular biology on high-school level",
              () => ShowCourse(1),
              5000,
              () => StartRollingDisappear(),
              "negotiations",
              () => ShowCourse(2),
              5000,
              () => StartRollingDisappear(),
            ]}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          {courses[shownCourse].map((topic, i) => (
            <TopicView shown={indexShown >= i} key={i} title={topic.title} description={topic.description} />
          ))}
        </div>
      </div>
    </HomePanelBase>
  );
};

export default HomeCoursesPanel;

const TopicView = ({ title, description, shown }: { title: string; description: string; shown: boolean }) => {
  const materials = useMemo(() => [Math.random() > 0.5 ? <VideoMaterial /> : <ArticleMaterial />, Math.random() > 0.5 ? <VideoMaterial /> : <ArticleMaterial />, Math.random() > 0.5 ? <PaperMaterial /> : <ArticleMaterial />], [title]);

  return (
    <div className={`w-full flex flex-col gap-1 p-2 text-mini md:text-sm rounded-lg bg-background border border-systemGray-200 select-none ${shown ? "opacity-100 scale-100" : "opacity-0 scale-90"} duration-300 ease`}>
      <div className="font-semibold text-foreground">{title}</div>
      <div className="text-systemGray-500 truncate">{description}</div>
      <div className="text-systemGray-300 flex items-center justify-start gap-1">
        <span className="text-foreground mr-1">Materials:</span>
        {materials.map((material, i) => (
          <span key={i}>{material}</span>
        ))}
      </div>
    </div>
  );
};

export const VideoMaterial = ({ className }: { className?: string }) => {
  return <span className={`text-brand-500 text-micro md:text-xs bg-brand-100 px-2 rounded-full ${className}`}>video</span>;
};
export const ArticleMaterial = ({ className }: { className?: string }) => {
  return <span className={`text-brandBlue-500 text-micro md:text-xs bg-brandBlue-100 px-2 rounded-full ${className}`}>article</span>;
};

export const PaperMaterial = ({ className }: { className?: string }) => {
  return <span className={`text-brandGreen-500 text-micro md:text-xs bg-brandGreen-100 px-2 rounded-full ${className}`}>paper</span>;
};
