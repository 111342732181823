import { Link } from "react-router-dom";
import CoursableLogo from "../elements/CoursableLogo";
import CTAButton from "../elements/CTAButton";
import { LargeWaves } from "../elements/VectorWaves/Waves";
import CoursableIcons from "../../utils/CoursableIcons";

const Footer = () => {
  function ClickHome() {
    window.location.href = "/";
  }

  return (
    <footer className="w-full bg-brand-700 dark:bg-brand-300 flex flex-col items-center justify-center overflow-clip relative">
      <BottomCTA />
      <div className="bg-brand-50">
        <LargeWaves />
      </div>
      <div className="w-full max-w-7xl grid grid-cols-2 sm:flex sm:flex-row items-start justify-between gap-8 sm:gap-16 px-4 py-8">
        <div className="flex flex-col items-start gap-4 mt-2 sm:order-first order-last col-span-2">
          <CoursableLogo onClick={ClickHome} wide size={6} className="bg-brand-100 px-2 py-1 rounded-lg" />
          <h5 className="text-white">Student workspace powered by AI</h5>
          <div className="flex gap-4 items-center justify-start">
            <SocialButton link="https://www.linkedin.com/company/coursable" icon={CoursableIcons.LinkedInLogo()} />
            <SocialButton link="https://www.twitter.com/coursableIO" icon={CoursableIcons.TwitterLogo()} />
            <SocialButton link="mailto:hello@coursable.io" icon={CoursableIcons.EnvelopeFill()} />
          </div>
          <div className="text-white/50 text-sm mt-auto">Copyright © {new Date().getFullYear()} Coursable</div>
        </div>
        <Column title="General">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/projects">Projects</NavLink>
          <NavLink to="/courses">Courses</NavLink>
          <NavLink to="/pricing">Pricing</NavLink>
          <NavLink to="/press">Press</NavLink>
        </Column>
        <Column title="User">
          <NavLink to="/app">My dashboard</NavLink>
          <NavLink to="/app/projects">My projects</NavLink>
          <NavLink to="/app/courses">My courses</NavLink>
          <NavLink to="/app/settings/profile">Settings</NavLink>
        </Column>
        <Column title="Legal">
          <NavLink to="/terms">Terms of service</NavLink>
          <NavLink to="/privacy">Privacy policy</NavLink>
        </Column>
      </div>
    </footer>
  );
};

export default Footer;

const Column = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-lg font-semibold text-white">{title}</div>
      {children}
    </div>
  );
};
const NavLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  return (
    <Link to={to} className="text-brand-300 dark:text-brand-700 hover:text-white dark:hover:text-white active:text-brand-100 duration-100">
      {children}
    </Link>
  );
};

export const SocialButton = ({ link, icon, className }: { link: string; icon: React.ReactNode; className?: string }) => {
  return (
    <a className={`text-lg p-[0.15rem] rounded text-brand-25 dark:text-brand-50 bg-systemGray-400/50 hover:bg-systemGray-300/50 active:bg-systemGray-200/50 duration-100 ${className}`} href={link} target="_blank" rel="noreferrer">
      {icon}
    </a>
  );
};

const BottomCTA = () => {
  return (
    <div className={`w-full flex flex-col gap-6 items-center justify-center pt-20 pb-4 px-4 text-center bg-gradient-to-t from-60% from-brand-50 to-background`}>
      <CoursableLogo size={10} />
      <h5 className="text-xl md:text-2xl font-semibold text-foreground">Ready to boost your learning?</h5>
      <p className="text-systemGray-500 max-w-md text-sm md:text-base">Power up your study materials and learn more efficiently with Coursable.</p>
      <CTAButton to="/signup" className="!text-lg">
        Try for Free
      </CTAButton>
    </div>
  );
};
