import { ReactNode } from "react";
import CoursableIcons from "../../../utils/CoursableIcons";
import { Link } from "react-router-dom";
import Button from "../../elements/Button";
import { cn } from "../../../utils/UtilityMethods";
import Badge from "../../elements/Badge";
import { HomePageMaterialPreview } from "./Projects/InfoBlocks/SummariesGraphic";
import { FaAlignLeft, FaBold, FaItalic, FaUnderline } from "react-icons/fa";
import { writingToolGroups } from "../Projects/Workspace/Notes/AITools";
import { WaveWrapper } from "./Shared";

const FeaturesBlock = () => {
  return (
    <WaveWrapper id="explore" topWavesContainerClassName="bg-brand-25" bottomWavesContainerClassName="bg-brand-25">
      <div className="w-full flex-centered flex-col px-4 pt-4 pb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-4 text-center text-white dark:text-white/90">
          Student Workspace <span className="">Powered by AI</span>
          {CoursableIcons.SparklesFill("ml-1 inline -mt-2")}
        </h2>
        <p className="text-systemGray-300/70 dark:text-systemGray-700/70 mb-14 text-center text-sm md:text-base">Join thousands of students from top institutions studying more efficiently with Coursable.</p>
        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8">
          <FeatureBlock title="Chat" description="Ask questions about your documents, videos, notes, and more." icon={CoursableIcons.ChatBubble()} product="projects" media={<SmallChatGraphic />} variant={0} />
          <FeatureBlock
            title="Flashcards & Quizzes"
            description="Generate, share, and practice with flashcards or quizzes made from your materials."
            icon={CoursableIcons.Flashcard()}
            product="projects"
            media={<SmallFlashcardsGraphic />}
            variant={1}
          />
          <FeatureBlock title="Summaries" description="Save hours of reading articles or watching videos with quick summaries of your materials." icon={CoursableIcons.Text()} product="projects" media={<SummariesGraphic />} variant={2} />
          <FeatureBlock title="Writing" description="Write essays, brainstorm ideas, proofread, or simply take notes with AI writing tools." icon={CoursableIcons.Signature()} product="projects" media={<WritingGraphic />} variant={3} />
        </div>
      </div>
    </WaveWrapper>
  );
};

export default FeaturesBlock;

interface FeatureBlockProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  product?: "projects" | "courses";
  variant: number;
  media: ReactNode;
  mediaClassName?: string;
}

const FeatureBlock = ({ title, description, icon, product, variant, media, mediaClassName }: FeatureBlockProps) => {
  const colors = [
    { border: "hover:border-red-400 dark:hover:border-red-700", button: "group-hover:text-red-600", icon: "text-red-500" },
    { border: "hover:border-blue-400 dark:hover:border-blue-700", button: "group-hover:text-blue-600", icon: "text-blue-500" },
    { border: "hover:border-green-400 dark:hover:border-green-700", button: "group-hover:text-green-600", icon: "text-green-500" },
    { border: "hover:border-amber-400 dark:hover:border-amber-700", button: "group-hover:text-amber-600", icon: "text-amber-500" },
  ];

  return (
    <Link
      to={`/app/${product}`}
      className={cn(
        "flex-centered flex-col md:flex-row p-8 gap-4 rounded-3xl duration-200 hover:shadow-xl hover:shadow-brand-600 dark:hover:shadow-brand-400 border-2 border-transparent bg-background/90 dark:bg-background/80 group relative",
        colors[variant].border
      )}
    >
      <div className="w-full md:w-1/2 h-full flex-started flex-col gap-4">
        <div className={cn("text-3xl", colors[variant].icon)}>{icon}</div>
        <h3 className="text-2xl font-semibold">{title}</h3>
        <h4 className="mb-auto">{description}</h4>
        <div className={cn("w-full bloc md:hidden mt-4", mediaClassName)}>{media}</div>
        <Button variant="link" className={cn("p-0 mt-4", colors[variant].button)}>
          Try Now →
        </Button>
      </div>
      <div className={cn("w-1/2 hidden md:block", mediaClassName)}>{media}</div>
    </Link>
  );
};

const SmallChatGraphic = () => {
  const aiStyles = "text-brand-500 text-left bg-brand-50 dark:bg-brand-100 rounded-r-lg rounded-tl-lg px-2 py-1 mr-auto flex items-center justify-start gap-1 truncate max-w-full";
  const userStyles = `text-foreground text-right px-2 py-0.5 ml-auto truncate`;
  return (
    <div className="w-full p-4 bg-background rounded-xl text-xs flex flex-col gap-3 items-center justify-between shadow-lg border border-systemGray-300 transition-transform group-hover:scale-105">
      <div className={userStyles}>List key ideas from this article</div>
      <div className={aiStyles}>{CoursableIcons.SparklesFill()} Sure! Here is what I found ...</div>
      <div className={userStyles}>Who wrote it?</div>
      <div className={aiStyles}>{CoursableIcons.SparklesFill()} The paper was written by ...</div>
    </div>
  );
};

const SmallFlashcardsGraphic = () => {
  const flashcardStyles = "w-full h-full bg-background rounded-lg flex-centered flex-col shadow-md absolute origin-bottom-left z-0 border border-systemGray-300";
  return (
    <div className="w-full h-44 py-8 mt-auto flex items-center flex-col justify-end select-none">
      <div className="w-[85%] h-24 relative flex items-center justify-center translate-x-2 group transition-transform">
        <div className={cn(flashcardStyles, "relative scale-90")}>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            3
          </Badge>
        </div>
        <div className={cn(flashcardStyles, "-rotate-3 group-hover:-rotate-6 transition-transform -mt-4 scale-95")}>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            2
          </Badge>
        </div>
        <div className={cn(flashcardStyles, "-rotate-6 group-hover:-rotate-12 transition-transform -mt-8")}>
          <div className="font-medium text-foreground">What is CPI?</div>
          <div className="text-systemGray-300 text-mini truncate max-w-full px-8">An index that measures the montly change in prices.</div>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            1
          </Badge>
        </div>
      </div>
    </div>
  );
};

const SummariesGraphic = () => {
  return (
    <div className="w-full max-w-xs h-44 py-4 mt-auto flex items-center flex-col justify-between">
      <HomePageMaterialPreview className="shadow-lg mr-auto" icon={CoursableIcons.PdfDoc("text-brand-500")} name="Research paper" />
      <HomePageMaterialPreview className="shadow-lg ml-auto" icon={CoursableIcons.PptxDoc("text-brand-500")} name="Lecture slides" />
      <HomePageMaterialPreview className="shadow-lg mr-auto ml-8" icon={CoursableIcons.DocxDoc("text-blue-500")} name="Book chapter" />
      <HomePageMaterialPreview className="shadow-lg ml-auto mr-4" icon={CoursableIcons.YouTube("text-brand-500")} name="Video session" />
    </div>
  );
};

const CoursesGraphic = () => {
  return (
    <div className="bg-background w-full flex-started flex-col p-4 rounded-xl shadow-lg transition-transform border border-systemGray-300 group-hover:scale-105 text-sm">
      <div className="text-brand-500 text-mini">Topic 1</div>
      <div className="font-semibold truncate max-w-full mb-2">Project Management 101</div>
      <div className="line-clamp-2 text-systemGray-500 mb-4">Learn how to define clear project goals and objectives to ensure that the project stays focused and aligned with the desired outcomes.</div>
      <div className="w-full flex-started gap-2">
        <Badge variant="blue">Video</Badge>
        <Badge variant="green">Article</Badge>
        <Badge variant="purple">Paper</Badge>
      </div>
    </div>
  );
};

const WritingGraphic = () => {
  return (
    <div className="bg-background w-full flex-centered gap-4 p-4 rounded-xl shadow-lg transition-transform border border-systemGray-300 text-sm group-hover:-translate-y-12">
      <div className="text-brand-500 transition-colors group-hover:bg-brand-50 rounded-md px-2 py-1 flex-centered gap-1 font-medium relative">
        {CoursableIcons.SparklesFill()} AI Tools {CoursableIcons.Chevron("down", "ml-1 text-brand-200")}
        <div className="absolute left-0 top-full pt-2 origin-top-left group-hover:opacity-100 opacity-0 group-hover:scale-100 scale-80 transition-all">
          <div className="flex-started flex-col p-2 bg-background rounded-md shadow-lg gap-2 border relative">
            {writingToolGroups[0].tools.slice(0, 5).map((tool, i) => (
              <div key={i} className="flex-centered gap-2 px-1 text-nowrap text-foreground">
                <span className="text-brand-500">{tool.icon}</span>
                {tool.label}
              </div>
            ))}
            <div className="bg-gradient-to-b from-transparent from-30% to-background absolute inset-0 rounded-md" />
          </div>
        </div>
      </div>
      <FaBold className="text-systemGray-700" />
      <FaItalic className="text-systemGray-700" />
      <FaUnderline className="text-systemGray-700" />
      <FaAlignLeft className="text-systemGray-700" />
    </div>
  );
};
