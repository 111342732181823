import { cva } from "class-variance-authority";
import React, { useEffect, useRef } from "react";
import { createContext, useContext } from "react";
import { useState } from "react";
import { IoWarningOutline as WarningIcon } from "react-icons/io5";
import { IoCheckmarkOutline as SuccessIcon } from "react-icons/io5";
import { IoCloseOutline as ErrorIcon } from "react-icons/io5";
import { IoInformation as InfoIcon } from "react-icons/io5";
import { cn } from "./UtilityMethods";

const NotificationsContext = createContext<NotificationsContextType>({
  sendSuccess: () => {},
  sendError: () => {},
  sendWarning: () => {},
  sendInfo: () => {},
});

type NotificationsContextType = {
  sendSuccess: (message: string, description?: string) => void;
  sendError: (message?: string, description?: string) => void;
  sendWarning: (message: string, description?: string) => void;
  sendInfo: (message: string, description?: string) => void;
};

interface Notification {
  id: string;
  type: "success" | "error" | "warning" | "info";
  title: string;
  description?: string;
}

function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function sendNotification(type: "success" | "error" | "warning" | "info", title: string, description?: string) {
    const notification: Notification = {
      id: Date.now().toString(),
      type,
      title,
      description,
    };
    setNotifications((prev) => [...prev, notification].slice(-5));
  }

  function RemoveNotification(id: string) {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }

  function sendSuccess(title: string, description?: string) {
    sendNotification("success", title, description);
  }
  function sendError(title?: string, description?: string) {
    sendNotification("error", title ?? "Oops, something went wrong. Please try again later.", description);
  }
  function sendWarning(title: string, description?: string) {
    sendNotification("warning", title, description);
  }
  function sendInfo(title: string, description?: string) {
    sendNotification("info", title, description);
  }

  const value: NotificationsContextType = {
    sendSuccess,
    sendError,
    sendWarning,
    sendInfo,
  };
  return (
    <NotificationsContext.Provider value={value}>
      {notifications.map((notification, index) => (
        <NotificationView key={notification.id} notification={notification} position={notifications.length - 1 - index} Remove={() => RemoveNotification(notification.id)} />
      ))}
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;

export function useNotifications() {
  return useContext(NotificationsContext);
}

const NotificationView = ({ notification, position, Remove }: { notification: Notification; position: number; Remove: () => void }) => {
  const hideNotificationTimer = useRef<NodeJS.Timeout | null>(null);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, 100);

    if (hideNotificationTimer.current) clearTimeout(hideNotificationTimer.current);
    hideNotificationTimer.current = setTimeout(() => {
      setIsShown(false);
      setTimeout(() => {
        Remove();
      }, 350);
    }, duration(notification.title, notification.description) * 1000);
  }, []);

  const offsetCoefficient = Math.pow(position, 0.7);
  const scaleCoefficient = position * 0.08;

  useEffect(() => {
    return () => {
      if (hideNotificationTimer.current) clearTimeout(hideNotificationTimer.current);
    };
  }, []);

  return (
    <div
      style={{
        top: `${isShown ? 4 - offsetCoefficient : -2.5}rem`,
        scale: `${(1 - scaleCoefficient) * 100}%`,
      }}
      className={`flex justify-center fixed left-0 right-0 z-[100] p-4 md:p-8 ${isShown ? "opacity-100" : "opacity-0"} duration-300 pointer-events-none`}
    >
      <Notification type={notification.type} title={notification.title} description={notification.description} />
    </div>
  );
};

const notificationVariants = cva("bg-background dark:bg-systemGray-100 flex items-center gap-3 px-4 py-3 backdrop-blur border border-systemGray-300 rounded-lg shadow-xl-c shadow-foreground/10 text-sm", {
  variants: {
    type: {
      info: "text-foreground",
      warning: "text-yellow-500",
      error: "text-red-500",
      success: "text-green-500",
    },
  },
});

export const Notification = ({ type, title, description }: { type: "success" | "error" | "warning" | "info"; title: string; description?: string }) => {
  return (
    <div className={cn(notificationVariants({ type }))}>
      {type === "info" && <InfoIcon className="flex-shrink-0" size={"1.5rem"} />}
      {type === "warning" && <WarningIcon className="flex-shrink-0" size={"1.5rem"} />}
      {type === "error" && <ErrorIcon className="flex-shrink-0" size={"1.5rem"} />}
      {type === "success" && <SuccessIcon className="flex-shrink-0" size={"1.5rem"} />}
      <div className="w-full flex flex-col gap-0.5">
        <div className="font-semibold">{title}</div>
        {description !== undefined && <div className="opacity-80 text-foreground">{description}</div>}
      </div>
    </div>
  );
};

const duration = (title: string, description?: string): number => {
  const wordsPerMin = 200.0; // Average read speed in English is 220, we reduce it to 200 for safety.
  const wordsPerSec = wordsPerMin / 60.0;

  const nWords = title.split(" ").length + (description ? description.split(" ").length : 0);

  return 1.5 + nWords / wordsPerSec; //We also add little time for safety and easiness.
};
