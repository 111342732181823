import CourseGenerator from "./CourseGenerator";
import CourseEditorSection from "./CourseEditorSection";
import HomePageTemplate from "../../../page-elements/HomePageTemplate";
import CoursableIcons from "../../../../utils/CoursableIcons";
import CTAButton from "../../../elements/CTAButton";
import { useEffect, useRef, useState } from "react";
import MaterialEvaluationBlock from "./InfoBlocks/MaterialEvaluationBlock";
import MaterialResearchBlock from "./InfoBlocks/MaterialResearchBlock";
import CourseGenerationBlock from "./InfoBlocks/CourseGenerationBlock";
import QuizGenerationBlock from "./InfoBlocks/QuizGenerationBlock";
import { InfoBlock } from "./InfoBlocks/InfoBlock";
import { cn } from "../../../../utils/UtilityMethods";
import { pageMetatags } from "../../../../utils/MetatagsGenerator";
import { HomePageScrollCTA, WaveWrapper } from "../Shared";

const CoursesHomePage = () => {
  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Courses",
        description:
          "Learn something new with personalized AI courses and approved study materials from the web. Coursable will generate a custom study guide, look up online learning materials, evaluate their quality, and provide you with an optimal course to get started.",
        ogImage: "/Images/OpenGraph/Courses Promo.png",
        canonical: "/courses",
      })}
    >
      <div className="flex flex-col items-center justify-center overflow-x-clip">
        <Hero />
        <HowItWorksView />
        <CourseEditorSection />
        <TechBehindCoursable />
      </div>
    </HomePageTemplate>
  );
};

export default CoursesHomePage;

const Hero = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-gradient-to-t from-brand-25 to-background to-70%">
      <div className="flex flex-col gap-10 items-center w-full max-w-xl py-24">
        {CoursableIcons.GraduationCapFill("text-4xl md:text-5xl text-brand-500")}
        <h1 className="text-4xl md:text-5xl font-bold text-center text-foreground">
          Generate Personalized{" "}
          <span
            style={{
              textShadow: "0px 5px 12px rgba(209, 36, 36, 0.2)",
            }}
            className="bg-gradient-to-tr from-brand-500 from-20% to-brand-300 text-transparent bg-clip-text"
          >
            Courses with AI
          </span>{" "}
        </h1>
        <p className="max-w-lg mx-4 text-lg text-center text-systemGray-500">Coursable makes it easy to start learning something new by generating personalized courses with approved study materials from the web.</p>
        <CourseGenerator />
      </div>
      <HomePageScrollCTA href="/courses/#how-it-works">
        Explore how <span className="font-semibold text-brand-400 group-hover:text-brand-500 duration-300">Courses</span> work
      </HomePageScrollCTA>
    </div>
  );
};

const HowItWorksView = () => {
  return (
    <WaveWrapper id="how-it-works" className="scroll-m-28" topWavesContainerClassName="bg-brand-25" bottomWavesContainerClassName="bg-brand-50">
      <div className={`w-full flex flex-col gap-10 py-10 items-center`}>
        <div className="flex gap-3 items-center">
          {CoursableIcons.StudentFill("text-3xl text-white mt-1")}
          <h5 className="text-2xl md:text-3xl font-semibold text-white">How it works</h5>
        </div>
        <p className="text-center max-w-4xl text-base md:text-lg text-brand-200 dark:text-brand-800 mx-4">
          Want to learn something new but don't know where to start? Coursable will generate a custom study guide, look up online learning materials, evaluate their quality, and provide you with an optimal course to get started.
        </p>
        <HowCoursesWorkSteps darkMode={true} />
        <CTAButton className="!text-base !shadow-brand-700 dark:!shadow-brand-300" to="/app/courses">
          Try for Free
        </CTAButton>
      </div>
    </WaveWrapper>
  );
};

export const HowItWorkSteps = ({ steps, darkMode, className }: { steps: { title: string; highlight: string; description: React.ReactNode; image: string; alt: string }[]; darkMode?: boolean; className?: string }) => {
  return (
    <div className={`w-full max-w-5xl flex flex-col gap-6 items-center px-4 ${className}`}>
      {steps.map((step, i) => (
        <Step key={i} {...step} darkMode={darkMode} step={i + 1} />
      ))}
    </div>
  );
};

export const HowCoursesWorkSteps = ({ darkMode }: { darkMode?: boolean }) => {
  return (
    <HowItWorkSteps
      darkMode={darkMode}
      steps={[
        {
          title: "Tell Coursable a subject that you'd like to study",
          highlight: "subject",
          description: `Coursable is best used as a tool to get started learning something new. It is a great starting point to begin your research, get a general understanding of the subject, and prepare you for more advanced courses online.\n\nCoursable is a perfect booster for your education, but its not a substitute for a profesionally taught course.`,
          image: "/Images/Home/Courses/HowItWorks-Step1.png",
          alt: "Coursable generate online course",
        },
        {
          title: "Ask AI for any changes",
          highlight: "changes",
          description: `Get the benefits of personal one-on-one education. Already know a particular topic or want to dive deeper into something else? Request any changes to optimize the course just for you.\n\nCoursable adapts to teach you exactly what you want to learn.`,
          image: "/Images/Home/Courses/HowItWorks-Step2.png",
          alt: "Coursable personalization",
        },
        {
          title: "Confirm the course and start studying",
          highlight: "Confirm",
          description: `Once you are satisfied with the structure, Coursable will find reputable online study materials, evaluate their quality, and provide you with the full course to get you started.\n\nUse the Coursable learning platform to go through the course, track your progress, and test your knowledge with AI quizzes.`,
          image: "/Images/Home/Courses/HowItWorks-Step3.png",
          alt: "Coursable dashboard",
        },
      ]}
    />
  );
};

const Step = ({ title, highlight, description, image, alt, step, darkMode }: { title: string; highlight: string; description: React.ReactNode; image: string; alt: string; step: number; darkMode?: boolean }) => {
  const [highlighted, setHighlighted] = useState<boolean>(false);
  const [scale, setScale] = useState(0.9);
  const [opacity, setOpacity] = useState(0.5);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (!ref.current) return;

      if (window.innerWidth < 768) return Highlight(true);

      var bounding = ref.current.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      const threashold = 0.2 * (screenHeight - bounding.height);

      if (bounding.height > screenHeight * 0.7 - 64) return Highlight(true);

      if (bounding.top >= threashold && bounding.bottom <= screenHeight - threashold) {
        Highlight(true);
      } else {
        Highlight(false);
      }
    };
    window.addEventListener("scroll", scrollHandler);

    scrollHandler();

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  function Highlight(isOn: boolean) {
    setHighlighted(isOn);
    setScale(isOn ? 1 : 0.9);
    setOpacity(isOn ? 1 : 0.5);
  }

  return (
    <div
      ref={ref}
      style={{
        transform: `scale(${mouseOver ? 1 : scale})`,
        opacity: mouseOver ? 1 : opacity,
      }}
      className="w-full flex flex-col gap-6 duration-300"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div className={`text-base md:text-lg font-semibold w-full flex flex-col gap-2 items-center justify-center`}>
        <span className={`${!!darkMode && "text-white"}`}>Step {step}</span>
        {CoursableIcons.Arrow("down", "text-2xl md:text-3xl text-brand-500")}
      </div>
      <div className={cn("flex md:flex-row flex-col gap-6 items-center justify-center", !!darkMode && "border-2 border-background rounded-xl bg-background/90 dark:bg-background/80 p-6", !!darkMode && highlighted && "shadow-xl")}>
        <div className="flex flex-col gap-6">
          <h5 className="text-xl md:text-2xl font-semibold text-center md:text-left">
            <span>{title.split(highlight)[0]}</span>
            <span className="text-brand-500">{highlight}</span>
            <span>{title.split(highlight)[1]}</span>
          </h5>
          <p className="text-sm text-systemGray-500 whitespace-pre-wrap max-w-3xl text-center md:text-left">{description}</p>
        </div>
        <img className={`shrink-0 md:w-1/2 duration-300 rounded-2xl bg-background p-2 bg-white border ${highlighted && (!!darkMode ? "shadow" : "shadow-xl-c")} duration-300`} src={image} alt={alt} />
      </div>
    </div>
  );
};

const TechBehindCoursable = () => {
  return (
    <div className="w-full flex-centered flex-col bg-gradient-to-b from-50% from-systemGray-100 to-background">
      <div className="w-full h-16 bg-gradient-to-b from-white to-systemGray-100 to-30%" />
      <div className="w-full flex flex-col items-center gap-10 max-w-5xl pb-20 px-4">
        <div className="flex gap-3 items-center justify-center w-full">
          {CoursableIcons.Code("text-2xl md:text-3xl text-brand-500 mt-1 stroke-[0.5]")}
          <h3 className="text-2xl md:text-3xl font-semibold">
            <span className="text-brand-500">Tech</span> behind Coursable
          </h3>
        </div>
        <InfoBlock
          side="left"
          title="Course generation"
          description="Our AI can generate an optimal course from a single prompt, covering the whole subject from the most basic to the most advanced concepts."
          Media={<CourseGenerationBlock />}
          smallTitle={true}
        />
        <InfoBlock
          side="right"
          title="Material research"
          description="Coursable will browse the internet on your behalf and analyze results to find educational materials for each topic."
          Media={<MaterialResearchBlock />}
          smallTitle={true}
        />
        <InfoBlock
          side="left"
          title="Material evaluation"
          description="Coursable's AI is trained to evaluate videos and web articles to measure how good they fit for teaching a particular subject, making sure you learn from the best."
          Media={<MaterialEvaluationBlock />}
          smallTitle={true}
        />
        <InfoBlock
          side="right"
          title="Quiz generation"
          description="Finally, our AI is optimized to read through the course and generate optional quizzes for your to test your knowledge."
          Media={<QuizGenerationBlock />}
          smallTitle={true}
        />
      </div>
    </div>
  );
};
