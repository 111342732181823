import { useEffect, useState } from "react";
import CoursableIcons from "../../../../utils/CoursableIcons";
import useTabs from "../../../../utils/Hooks/useTabs";
import useResizeHorizontal from "../../../../utils/Hooks/useResizeHorizontal";
import BottomSlideover from "./MaterialsViewer/BottomSlideoverMaterialsViewer";
import ExpandedMaterialsViewer from "./MaterialsViewer/ExpandedMaterialsViewer";
import ProjectSummaries from "./Summaries/ProjectSummaries";
import ProjectFlashcards from "./Flashcards/ProjectFlashcards";
import ProjectQuizzes from "./Quizzes/ProjectQuizzes";
import LoadingIndicator from "../../../elements/LoadingIndicator";
import AddMaterialsPopup from "./MaterialsViewer/AddMaterialsPopup";
import { cn } from "../../../../utils/UtilityMethods";
import { useProjectManager } from "../Hooks/useProjectManager";
import ProjectNotes from "./Notes/ProjectNotes";

const WorkspaceView = ({ collapsed }: { collapsed: boolean }) => {
  const { Files, project } = useProjectManager();

  const [opacity, setOpacity] = useState(0);
  const [selectedFileID, setSelectedFileID] = useState<string | undefined>(Files.files.length > 0 ? Files.files[0].id : undefined);
  const selectedFile = Files.files.find((f) => f.id === selectedFileID);

  const { rightWidth, DragHandle, isResizing } = useResizeHorizontal();
  const [materialsViewerExpanded, setMaterialsViewerExpanded] = useState<boolean>(false);
  const [isAddMaterialsPopupOpen, setIsAddMaterialsPopupOpen] = useState<boolean>(false);

  const { TabsBar, TabView } = useTabs([
    {
      label: "Summaries",
      icon: project?.isGeneratingSummary ? <LoadingIndicator className="h-4 w-4" /> : CoursableIcons.Text(),
      content: <ProjectSummaries materialsViewerExpanded={materialsViewerExpanded} />,
    },
    {
      label: "Flashcards",
      icon: project?.isGeneratingFlashcards ? <LoadingIndicator className="h-4 w-4" /> : CoursableIcons.Flashcard(),
      content: <ProjectFlashcards />,
    },
    {
      label: "Quizzes",
      icon: project?.isGeneratingQuiz ? <LoadingIndicator className="h-4 w-4" /> : CoursableIcons.Quizzes(),
      content: <ProjectQuizzes />,
    },
    {
      label: "Writing",
      icon: CoursableIcons.Edit(),
      content: <ProjectNotes />,
    },
  ]);

  useEffect(() => {
    if (selectedFileID && !Files.files.find((f) => f.id === selectedFileID)) setSelectedFileID(Files.files.length > 0 ? Files.files[0].id : undefined);
    else if (Files.files.length > 0) setSelectedFileID(Files.files[0].id);
  }, [Files.files]);

  useEffect(() => {
    if (collapsed) setMaterialsViewerExpanded(false);
  }, [collapsed]);

  useEffect(() => {
    if (project && selectedFile) setOpacity(1);
    else setOpacity(0);
  }, [project, selectedFile]);

  if (!project || !selectedFile) return <div className="w-full h-full bg-background" />;

  return (
    <div
      style={{
        gridTemplateColumns: !materialsViewerExpanded ? "auto" : `minmax(0, 1fr) 2px clamp(20%, ${rightWidth}px, 80%)`,
        transition: isResizing ? "" : "grid-template-columns 300ms",
      }}
      className="w-full h-full bg-background grid overflow-auto relative"
    >
      <div className={`w-full h-full flex flex-col relative ${collapsed ? "opacity-0" : "opacity-100"} duration-300 overflow-hidden`}>
        {TabsBar("px-4 pt-2 w-full")}
        {TabView("w-full flex overflow-auto grow")}
        {!materialsViewerExpanded && <BottomSlideover selectedFile={selectedFile} Expand={() => setMaterialsViewerExpanded(true)} SelectFile={(id) => setSelectedFileID(id)} OpenAddFiles={() => setIsAddMaterialsPopupOpen(true)} />}
      </div>
      {materialsViewerExpanded && (
        <>
          {collapsed ? <div /> : DragHandle}
          <ExpandedMaterialsViewer selectedFile={selectedFile} Collapse={() => setMaterialsViewerExpanded(false)} SelectFile={(id) => setSelectedFileID(id)} OpenAddFiles={() => setIsAddMaterialsPopupOpen(true)} />
        </>
      )}
      <AddMaterialsPopup isOpen={isAddMaterialsPopupOpen} setIsOpen={setIsAddMaterialsPopupOpen} />
      <div className={cn("absolute inset-0 w-full h-full bg-background z-10 duration-300 opacity-100 pointer-events-none", opacity === 1 && "opacity-0")} />
    </div>
  );
};

export default WorkspaceView;
