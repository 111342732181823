import { useNavigate } from "react-router-dom";
import HomePageTemplate from "../../../page-elements/HomePageTemplate";
import CoursableIcons from "../../../../utils/CoursableIcons";
import CTAButton from "../../../elements/CTAButton";
import { LargeWaves, SmallWaves } from "../../../elements/VectorWaves/Waves";
import ProjectInfoBlock from "./InfoBlocks/ProjectInfoBlock";
import FlashcardsGraphic from "./InfoBlocks/FlashcardsGraphic";
import HeroGraphic from "./HeroGraphic";
import ChatGraphic from "./InfoBlocks/ChatGraphic";
import SummariesGraphic, { HomePageMaterialPreview } from "./InfoBlocks/SummariesGraphic";
import QuizzesGraphic from "./InfoBlocks/QuizzesGraphic";
import Badge from "../../../elements/Badge";
import { cn } from "../../../../utils/UtilityMethods";
import { pageMetatags } from "../../../../utils/MetatagsGenerator";
import DemoTextEditor from "./DemoTextEditor";
import { HomePageScrollCTA, WaveWrapper } from "../Shared";

const ProjectsHomePage = () => {
  return (
    <HomePageTemplate
      helmet={pageMetatags({
        title: "Projects",
        description: "Generate flashcards and quizzes from your lecture notes, articles, videos, or anything else. Summarize documents, pull insights, brainstorm ideas, and find answers faster.",
        ogImage: "/Images/OpenGraph/Projects Promo.png",
        canonical: "/projects",
      })}
    >
      <div className="flex flex-col items-center mx-auto overflow-x-clip">
        <Hero />
        <HowProjectsWorks />
        <ProjectWorkspace />
        <WritingWorkspace />
      </div>
    </HomePageTemplate>
  );
};

export default ProjectsHomePage;

const Hero = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-gradient-to-t from-brand-25 to-background to-70%">
      <div className="flex flex-col md:flex-row items-center justify-center w-full max-w-5xl pt-8 md:pt-24 pb-16 px-4 gap-28 md:gap-0">
        <div className="w-full md:w-1/2 flex flex-col items-center md:items-start shrink-0 text-center md:text-left">
          {CoursableIcons.ProjectFill("text-5xl text-brand-500")}
          <div className="relative">
            <h1 className="text-4xl md:text-5xl font-bold my-8 text-foreground md:leading-tight">
              Student Workspace
              <br />
              <span
                style={{
                  textShadow: "0px 5px 12px rgba(209, 36, 36, 0.2)",
                }}
                className="bg-gradient-to-tr from-brand-500 from-20% to-brand-300 text-transparent bg-clip-text"
              >
                Powered by AI
              </span>{" "}
            </h1>
            {CoursableIcons.SparklesFill("absolute -top-4 -right-4 md:-right-12 text-brand-100 text-4xl md:text-5xl")}
          </div>
          <p className="max-w-sm text-lg text-systemGray-500">
            Generate flashcards and quizzes from your lecture notes, articles, videos, or anything else. Summarize, pull insights, brainstorm ideas, or write with AI tools powered by your documents.
          </p>
          <CTAButton className="text-lg md:text-xl mt-16" to="/app/projects/new">
            Start Now
          </CTAButton>
        </div>
        <HeroGraphic />
      </div>
      <HomePageScrollCTA href="/projects/#how-it-works">
        Explore what you can do with <span className="font-semibold text-brand-400 group-hover:text-brand-500 duration-300">Projects</span>
      </HomePageScrollCTA>
    </div>
  );
};

const HowProjectsWorks = () => {
  return (
    <WaveWrapper id="how-it-works" className="scroll-m-28" topWavesContainerClassName="bg-brand-25" bottomWavesContainerClassName="bg-brand-50">
      <div className={"w-full grid gap-10 grid-cols-1 md:grid-cols-3 px-4 py-8 max-w-md md:max-w-5xl"}>
        <HowItWorksSteps title="Add your materials" description="Upload documents, videos, and web articles into one project." header="Projects" headerIcon={CoursableIcons.Doc()} graphic={<Step1Graphic />} />
        <HowItWorksSteps title="Chat with insights" description="Ask AI to pull insights, brainstorm ideas, solve assignments, and more." header="Chat" headerIcon={CoursableIcons.Doc()} graphic={<Step2Graphic />} />
        <HowItWorksSteps title="Study using AI" description="Generate flashcards, quizzes, or write text using tools powered by your content." header="Workspace" headerIcon={CoursableIcons.Doc()} graphic={<Step3Graphic />} />
      </div>
    </WaveWrapper>
  );
};

const HowItWorksSteps = ({ header, headerIcon, title, description, graphic }: { header: string; headerIcon: React.ReactNode; title: string; description: string; graphic?: React.ReactNode }) => {
  return (
    <div className="w-full flex-centered flex-col text-center pb-6">
      <div className={cn("text-lg md:text-base flex items-center justify-start gap-2 font-medium pb-1 text-black/50")}>
        <div className="text-base">{headerIcon}</div>
        {header}
      </div>
      <h3 className={cn("text-2xl font-semibold text-white")}>{title}</h3>
      <p className={cn("max-w-xs text-base pt-6 pb-8 font-light text-white/60")}>{description}</p>
      {graphic}
    </div>
  );
};

const Step1Graphic = () => {
  return (
    <div className="w-full max-w-xs h-44 py-4 mt-auto flex items-center flex-col justify-between">
      <HomePageMaterialPreview className="shadow-xl mr-auto" icon={CoursableIcons.PdfDoc("text-brand-500")} name="Class notes 4/30" />
      <HomePageMaterialPreview className="shadow-xl ml-auto" icon={CoursableIcons.PptxDoc("text-brand-500")} name="Lecture slides" />
      <HomePageMaterialPreview className="shadow-xl mr-auto ml-8" icon={CoursableIcons.DocxDoc("text-blue-500")} name="Past exam" />
      <HomePageMaterialPreview className="shadow-xl ml-auto mr-4" icon={CoursableIcons.YouTube("text-brand-500")} name="Online session" />
    </div>
  );
};

const Step2Graphic = () => {
  const aiStyles = "text-brand-500 text-left bg-brand-50 dark:bg-brand-100 rounded-r-lg rounded-tl-lg px-2 py-1 mr-auto flex items-center justify-start gap-1 truncate max-w-full";
  const userStyles = `text-foreground text-right px-2 py-0.5 ml-auto truncate`;
  return (
    <div className="w-full h-44 p-2 mt-auto flex flex-col items-center justify-center select-none px-4">
      <div className="w-full h-full p-4 bg-background rounded-lg text-xs flex flex-col items-center justify-between shadow-lg ring-4 ring-brand-200 transition-transform hover:scale-105">
        <div className={userStyles}>List key ideas from this article</div>
        <div className={aiStyles}>{CoursableIcons.SparklesFill()} Sure! Here is what I found ...</div>
        <div className={userStyles}>Who wrote it?</div>
        <div className={aiStyles}>{CoursableIcons.SparklesFill()} The paper was written by ...</div>
      </div>
    </div>
  );
};

const Step3Graphic = () => {
  const flashcardStyles = "w-full h-full bg-background rounded-lg flex-centered flex-col shadow-md absolute origin-bottom-left z-0 border border-systemGray-300";
  return (
    <div className="w-full h-44 py-8 mt-auto flex items-center flex-col justify-end select-none">
      <div className="w-[75%] h-24 relative flex items-center justify-center translate-x-2 group hover:scale-105 transition-transform">
        <div className={cn(flashcardStyles, "relative scale-90")}>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            3
          </Badge>
        </div>
        <div className={cn(flashcardStyles, "-rotate-3 group-hover:-rotate-6 transition-transform -mt-4 scale-95")}>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            2
          </Badge>
        </div>
        <div className={cn(flashcardStyles, "-rotate-6 group-hover:-rotate-12 transition-transform -mt-8")}>
          <div className="font-medium text-foreground">What is CPI?</div>
          <div className="text-systemGray-300 text-mini truncate max-w-full px-8">An index that measures the montly change in prices.</div>
          <Badge variant="outline" className="absolute bottom-2 right-2 text-micro">
            1
          </Badge>
        </div>
      </div>
    </div>
  );
};

const ProjectWorkspace = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-gradient-to-b from-brand-50 to-20% to-systemGray-100 pb-20">
      <div className="w-full flex flex-col items-center max-w-5xl pt-16 px-4">
        <div className="w-full flex flex-col gap-8 items-center pt-10 px-4">
          <div className="flex gap-2 items-center justify-center text-2xl md:text-3xl">
            {CoursableIcons.LayoutColumns("three", "text-brand-500")}
            <h3 className="text-center font-semibold text-foreground">
              Coursable <span className="text-brand-500">Projects</span>
            </h3>
          </div>
          <p className="text-systemGray-500 max-w-2xl text-center">
            A robust <span className="opacity-80 px-1 pb-[1px] font-semibold rounded-md text-brand-500 border bg-brand-50 border-brand-200">AI workspace</span> for students. Generate, edit, and distribute study materials based on your
            documents, lectures, and videos. Study more efficiently with AI flashcards, summaries, and quizzes, share your work with others, and more.
          </p>
        </div>
        <div className="w-full flex flex-col items-center gap-40 pt-28">
          <ProjectInfoBlock
            side="left"
            icon={CoursableIcons.ChatBubble()}
            header="Chat"
            title="Documents and videos"
            description="Engage with your study materials through an AI-powered chat. Ask questions, pull insights, and get work done with AI results tailored to your needs."
            Media={<ChatGraphic />}
          />
          <ProjectInfoBlock
            side="right"
            icon={CoursableIcons.Text()}
            header="Summaries"
            title="Quick materials overview"
            description="Quickly extract and summarize core information from your educational materials. Ask AI to focus on something specific or make a general summary of your content."
            Media={<SummariesGraphic />}
          />
          <ProjectInfoBlock
            side="left"
            icon={CoursableIcons.Flashcard()}
            header="Flashcards"
            title="Personalized study cards"
            description="Transform your study materials into personalized flashcards. Practice your knowledge usign dynamic suggestions from AI."
            Media={<FlashcardsGraphic />}
          />
          <ProjectInfoBlock
            side="right"
            icon={CoursableIcons.Quizzes()}
            header="Quizzes"
            title="Interactive AI quizzes"
            description="Prepare for exams with AI quizzes generated from your documents. Solve questions together with AI or share problem sets to practice with friends."
            Media={<QuizzesGraphic />}
          />
        </div>
      </div>
    </div>
  );
};

const WritingWorkspace = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-gradient-to-b from-systemGray-100 to-5% to-background gap-8 py-20">
      <div className="flex gap-2 items-center justify-center text-2xl md:text-3xl">
        {CoursableIcons.Signature("text-brand-500 text-3xl md:text-4xl")}
        <h3 className="text-center font-semibold text-foreground">Writing Workspace</h3>
      </div>
      <p className="text-systemGray-500 max-w-2xl text-center pb-12">
        A powerful education focused writing workspace with <span className="opacity-80 px-1 pb-[1px] font-semibold rounded-md text-brand-500 border bg-brand-50 border-brand-200">AI tools</span> that know your materials. Coursable can use
        your documents to help create outlines, craft arguments, edit essays, or anything else you ask it.
      </p>
      <div className="w-full px-8 max-w-3xl">
        <DemoTextEditor />
      </div>
    </div>
  );
};
