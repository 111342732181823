import { useState } from "react";
import { ProjectFile } from "../../../../backend/Projects/types";
import AIButton from "../../../elements/AIButton";
import Checkbox from "../../../elements/Checkbox";
import Dropdown from "../../../elements/DropdownMenu/Dropdown";
import InputField from "../../../elements/InputField";
import { cn } from "../../../../utils/UtilityMethods";

interface GenerationBlockProps {
  prompt: string;
  generating: boolean;
  empty: boolean;
  files: ProjectFile[];
  actions?: React.ReactNode;
  selectedFiles: ProjectFile[];
  setPrompt: (p: string) => void;
  setSelectedFiles: (f: ProjectFile[]) => void;
  ClickGenerate: () => void;
  promptPlaceholder: string;
  className?: string;
}

export const useGenerationBlock = (defaultFiles: ProjectFile[]) => {
  const [prompt, setPrompt] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<ProjectFile[]>(defaultFiles);

  return {
    prompt,
    setPrompt,
    selectedFiles,
    setSelectedFiles,
  };
};

export const GenerationBlock = ({ prompt, generating, empty, actions, className, setPrompt, ClickGenerate, promptPlaceholder }: GenerationBlockProps) => {
  return (
    <div className={cn("w-full @container z-2", className)}>
      <div className="w-full flex flex-col @sm:flex-row items-center justify-center gap-4 @sm:gap-2">
        <InputField
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              ClickGenerate();
              e.currentTarget.blur();
            }
          }}
          className={`w-full ${!!generating && "animate-pulse pointer-events-none"}`}
          placeholder={promptPlaceholder}
          value={prompt}
          onValueChange={setPrompt}
        />
        {actions}
        {/* <FileSelector className="w-full @sm:max-w-[180px] overflow-x-clip @sm:overflow-x-visible" allFiles={files} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} generating={generating} /> */}
        <AIButton onClick={ClickGenerate} generating={generating} className="w-40">
          {!!generating ? "Generating" : !empty ? "Regenerate" : "Generate"}
        </AIButton>
      </div>
    </div>
  );
};

interface FileSelectorProps {
  generating: boolean;
  allFiles: ProjectFile[];
  selectedFiles: ProjectFile[];
  setSelectedFiles: (f: ProjectFile[]) => void;
  className?: string;
}

const FileSelector = ({ generating, allFiles, selectedFiles, setSelectedFiles, className }: FileSelectorProps) => {
  function ToggleFile(file: ProjectFile, isSelected: boolean) {
    if (isSelected) {
      if (!selectedFiles.some((f) => f.id === file.id)) setSelectedFiles([...selectedFiles, file]);
      else if (isAllSelected) setSelectedFiles([file]);
    } else {
      if (selectedFiles.length > 1) setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
    }
  }

  const isOneSelected = selectedFiles.length === 1;
  const isAllSelected = selectedFiles.length === allFiles.length;

  return (
    <Dropdown className={`${generating && "pointer-events-none animate-pulse"} ${className}`} closeOnClick={false} label={isOneSelected ? selectedFiles[0].name : isAllSelected ? "All documents" : `${selectedFiles.length} documents`}>
      <div className="w-[300px] flex flex-col justify-center gap-2 p-2">
        <div className="text-sm text-systemGray-500">Select which documents to include in the summary.</div>
        <div className="w-full h-[1px] bg-systemGray-200" />
        <Checkbox
          label="All documents"
          size="sm"
          variant="dynamic"
          checked={isAllSelected}
          setChecked={() => {
            setSelectedFiles(allFiles);
          }}
          className="my-2"
        />
        <div className="w-full flex items-center gap-2 mb-2">
          <div className="w-full h-[1px] bg-systemGray-200" />
          <div className="text-mini text-systemGray-500 -my-2">or</div>
          <div className="w-full h-[1px] bg-systemGray-200" />
        </div>
        {allFiles.map((file) => {
          return (
            <Checkbox
              key={file.id}
              label={file.name}
              size="sm"
              variant="dynamic"
              checked={selectedFiles.includes(file) && selectedFiles.length !== allFiles.length}
              setChecked={(isSelected) => {
                ToggleFile(file, isSelected);
              }}
            />
          );
        })}
      </div>
    </Dropdown>
  );
};
